<template>
 <div class="overflow-hidden h-full bg-white py-24 sm:py-32">
  <div class="mx-auto max-w-7xl px-6 lg:px-8">
   <header class="border-b w-full">
    <nav class="flex overflow-x-auto py-4">
     <ul
      role="list"
      class="flex flex-none gap-x-6 px-4 text-sm font-semibold leading-6 text-gray-700 sm:px-6 lg:px-8 items-center">
      <li v-for="item in navigation" :key="item.name">
       <router-link
        :to="`/expert-statistics/home/${item.href}`"
        :class="[
         item.current ? 'text-white bg-teal-600' : '',
         'px-2 py-1 rounded-lg hover:bg-teal-600 hover:text-white',
        ]"
        >{{ $t(item.name) }}</router-link
       >
      </li>
     </ul>
    </nav>
   </header>
   <div
    v-if="menu === 'intro'"
    class="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2 pt-2">
    <div class="lg:pr-8 lg:pt-4">
     <div class="lg:max-w-lg">
      <p
       class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
       Bienvenue sur Expert Stats
      </p>
      <p class="mt-6 text-lg leading-8 text-gray-600">
       Expert Stats propose {{ features?.length - 1 }} analyses des flux
       d'appels pour améliorer l’expérience client et la performance des
       équipes.
      </p>
      <dl
       class="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
       <div
        v-for="feature in features"
        :key="feature.name"
        class="relative pl-9">
        <dt
         v-if="feature.description"
         class="inline font-semibold text-teal-700">
         <component
          :is="feature.icon"
          class="absolute left-1 top-1 h-5 w-5 text-teal-600"
          aria-hidden="true" />
         <router-link :to="`/expert-statistics/home/${feature.href}`"
          >{{ feature.name }}.</router-link
         >
        </dt>
        {{ " " }}
        <dd class="inline">{{ feature.description }}</dd>
       </div>
      </dl>
     </div>
    </div>
    <div class="rounded overflow-hidden shadow-lg bg-white">
     <iframe
      :src="youtubeEmbedUrlIntro"
      class="w-full h-full"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen>
     </iframe>
     <div class="px-6 py-4">
      <div class="font-bold text-xl mb-2">
       Découvrez en 3 min comment utiliser Expert Stats
      </div>
     </div>
    </div>
   </div>
   <div v-else class="mx-auto pt-2">
    <div class="h-full">
     <div>
      <div class="grid grid-cols-1 sm:hidden w-full">
       <select
        aria-label="Select a tab"
        class="col-start-1 row-start-1 w-full appearance-none rounded-t-md bg-white py-2 pl-3 pr-8 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-teal-600">
        <option v-for="tab in tabs" :key="tab.name" :selected="tab.current">
         {{ tab.name }}
        </option>
       </select>
       <ChevronDownIcon
        class="pointer-events-none col-start-1 row-start-1 mr-2 size-5 self-center justify-self-end fill-gray-500"
        aria-hidden="true" />
      </div>
      <!-- <div class="hidden sm:block">
       <nav
        class="isolate flex divide-x divide-gray-200 rounded-t-lg shadow"
        aria-label="Tabs">
        <a
         v-for="(tab, tabIdx) in tabs"
         :key="tab.name"
         :href="tab.href"
         :class="[
          tab.current ? 'text-gray-900' : 'text-gray-500 hover:text-gray-700',
          tabIdx === 0 ? 'rounded-t-l-lg' : '',
          tabIdx === tabs.length - 1 ? 'rounded-t-r-lg' : '',
          'group relative min-w-0 flex-1 overflow-hidden bg-white px-4 py-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10',
         ]"
         :aria-current="tab.current ? 'page' : undefined">
         <span>{{ tab.name }}</span>
         <span
          aria-hidden="true"
          :class="[
           tab.current ? 'bg-teal-600' : 'bg-transparent',
           'absolute inset-x-0 top-0 h-0.5',
          ]" />
        </a>
       </nav>
      </div> -->
     </div>
     <!-- <div class="lg:pr-8 lg:pt-4">
      <div class="lg:max-w-lg">
       <p
        class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
        Bienvenue sur Expert Stats
       </p>
       <p class="mt-6 text-lg leading-8 text-gray-600">
        Expert Stats propose {{ features?.length - 1 }} analyses des flux
        d'appels pour améliorer l’expérience client et la performance des
        équipes.
       </p>
       <dl
        class="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
        <div
         v-for="feature in features"
         :key="feature.name"
         class="relative pl-9">
         <dt
          v-if="feature.description"
          class="inline font-semibold text-teal-700">
          <component
           :is="feature.icon"
           class="absolute left-1 top-1 h-5 w-5 text-teal-600"
           aria-hidden="true" />
          <router-link :to="`/expert-statistics/home/${feature.href}`">{{
           feature.name
          }}</router-link>
          .
         </dt>
         {{ " " }}
         <dd class="inline">{{ feature.description }}</dd>
        </div>
       </dl>
      </div>
     </div> -->
     <div
      v-for="video in videosTabs"
      :key="video.name"
      v-show="menu === video.href"
      class="rounded-b-lg overflow-hidden shadow-lg bg-white">
      <iframe
       :src="youtubeEmbedUrlIntroTabs(video.videoUrlCode)"
       class="w-full video-height"
       frameborder="0"
       allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
       allowfullscreen>
      </iframe>
      <div class="px-6 py-4">
       <div class="font-bold text-xl mb-2">
        {{ video.description }}
       </div>
      </div>
     </div>
    </div>
   </div>
  </div>
 </div>
</template>

<script>
import {
 PhoneIncomingIcon,
 UsersIcon,
 UserGroupIcon,
 ChevronDownIcon,
} from "@heroicons/vue/solid";

const tabs = [
 { name: "Video 1", href: "#", current: false },
 //  { name: "Video 2", href: "#", current: false },
 //  { name: "Video 3", href: "#", current: true },
 //  { name: "Video 4", href: "#", current: false },
];

const videosTabs = [
 {
  name: "Mes Numéros",
  description: "4 minutes pour prendre en main Mes Numéros",
  href: "my-numbers",
  videoUrlCode: "1fbh10Oc0QY",
 },
 {
  name: "Mes File d'attente",
  description: "3 minutes pour prendre en main Mes Files d'attentes.",
  href: "queues",
  videoUrlCode: "ZlZmWu1pouY",
 },
 {
  name: "Mes Utilisateurs",
  description: "3 minutes pour prendre en main Mes utilisateurs.",
  href: "users",
  videoUrlCode: "7q0AdEMj9wM",
 },
 {
  name: "Création Groupe",
  href: "config",
  description: "Personnaliser vos Rapports détaillés.",
  videoUrlCode: "GHOqrp-AYe8",
 },
 {
  name: "Rapport & Alerte",
  href: "reports-alerts",
  description: "Paramétrer l'envoi de rapports réguliers et d'alertes.",
  videoUrlCode: "adwOhnxbxqc",
 },
];

const features = [
 {
  name: "Intro",
  href: "intro",
  current: false,
 },
 {
  name: "Mes Numéros",
  description:
   "Analysez tous les appels entrants de votre numéro de standard, du numéro d’un service ou d’un utilisateur.",
  icon: PhoneIncomingIcon,
  href: "my-numbers",
  current: false,
 },
 {
  name: "Mes File d'attente",
  description:
   "Analysez les appels entrants par file d'attente pour améliorer la gestion des temps d'attente et l'expérience client.",
  icon: UserGroupIcon,
  href: "queues",
  current: false,
 },
 {
  name: "Mes Utilisateurs",
  description:
   "Analysez les stats individuelles des agents pour équilibrer la charge de travail et optimiser les performances.",
  icon: UsersIcon,
  href: "users",
  current: false,
 },
 {
  name: "Création Groupe",
  icon: UsersIcon,
  href: "config",
  current: false,
 },
 {
  name: "Rapport & Alerte",
  icon: UsersIcon,
  href: "reports-alerts",
  current: false,
 },
];

export default {
 props: ["menu"],
 data() {
  return {
   features,
   tabs,
   videosTabs,
   youtubeUrlIntro: "https://www.youtube.com/watch?v=M3xCymv94JE",
  };
 },
 components: {
  PhoneIncomingIcon,
  UsersIcon,
  UserGroupIcon,
 },
 methods: {
  youtubeEmbedUrlIntroTabs(videoId) {
   return `https://www.youtube.com/embed/${videoId}`;
  },
 },
 computed: {
  youtubeEmbedUrlIntro() {
   const videoId = this.youtubeUrlIntro.split("v=")[1];
   return `https://www.youtube.com/embed/${videoId}`;
  },
  navigation() {
   return this.features.map(nav => {
    nav.href === this.menu ? (nav.current = true) : (nav.current = false);
    return nav;
   });
  },
 },
};
</script>
<style>
.video-height {
 height: 600px;
}
</style>
